<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-accent"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.code.$error }">
                                <label for="code">Mã đơn vị tính</label>
                                <md-input name="code" v-model="entity.code"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.code.required">Vui lòng nhập mã đơn vị tính</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.unitName.$error }">
                                <label for="unitName">Tên đơn vị tính</label>
                                <md-input name="unitName" v-model="entity.unitName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.unitName.required">Vui lòng nhập tên đơn vị tính</span>
                            </md-field>

                            <div class="form-control">
                                <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import unitService from '../../../api/unitService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Thêm/Cập nhật danh mục đơn vị tính'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               entity: { id: 0, code: '', unitName: '', orderType: 1, isActive: true },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật đơn vị tính';
               this.getById();
            }
            else{
               this.title = 'Thêm mới đơn vị tính';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
               this.submitted = true;
               this.$v.$touch();
               if (this.$v.$invalid) {
                   return;
               }
               if(this.id > 0){
                   this.edit();
               }
               else{
                   this.add();
               }
            },

            add(){
                this.setLoading(true);
                this.entity.orderType = 1;
                unitService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                unitService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/unit');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                unitService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/unit');
            },
        },
        validations: {
            entity: {
                code: { required },
                unitName: { required },
            }
        },
    })

</script>
